<template>
  <div class="faulty active">
    <div class="title">
      <span>공정 불량 내역</span>
    </div>
    <div class="faulty_cont" v-if="seq_verification != null">
      <div class="faulty_data">
        <div
          class="faulty_list"
          v-for="(item, index) in seq_verification.seq_defect_list"
          :key="item.id"
        >
          <my-local-selectric
            v-if="showSelectric"
            :id="`seq_verification_selectric${index + String(seq_id)}`"
            :watch="item.verification_id"
            :options="
              seq_verification_options.filter(
                x =>
                  x.value == item.verification_id ||
                  !filter_veri_options.includes(x.value),
              )
            "
            @changeValue="setSearchType($event, index)"
            :state="
              index != seq_verification.seq_defect_list.length - 1
                ? 'disabled'
                : ''
            "
          >
          </my-local-selectric>
          <input
            type="text"
            placeholder="수량 입력"
            :value="$makeComma(item.unpass_quantity)"
            inputmode="decimal"
            @input="$inputNumber($event, item, 'unpass_quantity')"
            :disabled="index != seq_verification.seq_defect_list.length - 1"
          />
          <button
            type="button"
            class="input_delete"
            @click="deleteRow(index)"
          ></button>
        </div>
        <div class="faulty_list">
          <button
            v-if="is_valid_last_data || seq_defect_list.length < 1"
            class="btn_tbl"
            @click="addNewRow()"
          >
            행 추가
          </button>
        </div>
      </div>
      <button
        class="btn_sub2"
        id="btnFaulty"
        v-if="is_valid_last_data"
        :disabled="isValidModify"
        @click="submitForm"
      >
        저장
      </button>
    </div>
    <new-verification
      v-if="showModal"
      :verification_type_id="2"
      @onclose="showModal = false"
    ></new-verification>
  </div>
</template>

<script>
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import fetchMixin from '@/mixins/fetch';
import NewVerification from '@/layouts/components/NewVerification';
import { mapGetters } from 'vuex';

export default {
  mixins: [fetchMixin],
  components: {
    MyLocalSelectric,
    NewVerification,
  },
  props: {
    seq_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showSelectric: false,
      seq_verification: null,
      cloneData: null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      seq_verifications: 'getSeqVerificationFromQualityManagement',
      seq_verification_options:
        'getSeqVerificationForSelectricFromQualityManagement',
      systemCompany: 'getSystemCompany',
    }),
    filter_veri_options() {
      if (this.seq_verifications.length > 0) {
        if (this.seq_verification.seq_defect_list.length > 1) {
          const concatData = this.lodash.clonedeep(
            this.seq_verification.seq_defect_list,
          );
          let list = [];
          concatData.forEach(element => {
            list.push(element.verification_id);
          });
          return list;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    seq_defect_list() {
      if (this.seq_verification != null) {
        return this.seq_verification.seq_defect_list;
      } else return [];
    },

    is_valid_last_data() {
      if (this.seq_verification != null) {
        if (this.seq_defect_list.length < 1) {
          return false;
        } else {
          const total_unpass_quantity = this.seq_defect_list
            .map(x => x.unpass_quantity)
            .reduce(
              (a, b) =>
                this.$decimalAdd(this.$makeNumber(a), this.$makeNumber(b)),
              0,
            );
          if (
            total_unpass_quantity >
            this.$makeNumber(this.seq_verification.seq_fail_quantity)
          ) {
            return false;
          } else if (
            this.seq_defect_list[this.seq_defect_list.length - 1]
              .verification_id == null
          ) {
            return false;
          } else {
            return true;
          }
        }
      } else return false;
    },
    isValidModify() {
      if (this.seq_verification != null && this.seq_defect_list != null) {
        let originData = JSON.stringify(
          this.lodash.clonedeep(this.cloneData.seq_defect_list),
        );
        let modifyData = this.seq_verification.seq_defect_list;
        modifyData.map(
          x => (x.unpass_quantity = this.$makeNumber(x.unpass_quantity)),
        );
        modifyData = JSON.stringify(modifyData);

        if (modifyData == originData) {
          return true;
        } else return false;
      } else return false;
    },
  },
  methods: {
    deleteRow(index) {
      this.seq_verification.seq_defect_list.splice(index, 1);
    },
    addNewRow() {
      console.log('addNewRow');
      if (this.seq_defect_list.length > 0) {
        this.seq_verification.seq_defect_list.push({
          id: this.seq_defect_list.length + 1,
          verification_id: null,
          check_quantity: this.seq_defect_list[this.seq_defect_list.length - 1]
            .pass_quantity,
          pass_quantity: this.seq_defect_list[this.seq_defect_list.length - 1]
            .pass_quantity,
          unpass_quantity: 0,
          pass_yn: true,
          image: null,
        });
        this.cloneData = this.lodash.clonedeep(this.seq_verification);
      } else {
        this.seq_verification.seq_defect_list.push({
          id: this.seq_defect_list.length + 1,
          verification_id: null,
          check_quantity: this.$decimalAdd(
            this.$makeNumber(this.seq_verification.seq_pass_quantity),
            this.$makeNumber(this.seq_verification.seq_fail_quantity),
          ),
          pass_quantity: this.$decimalAdd(
            this.$makeNumber(this.seq_verification.seq_pass_quantity),
            this.$makeNumber(this.seq_verification.seq_fail_quantity),
          ),
          unpass_quantity: 0,
          pass_yn: true,
          image: null,
        });
        this.cloneData = this.lodash.clonedeep(this.seq_verification);
      }
    },
    submitForm() {
      const payload = this.lodash.clonedeep(this.seq_verification);
      const total_unpass_quantity = this.seq_defect_list
        .map(x => x.unpass_quantity)
        .reduce(
          (a, b) => this.$decimalAdd(this.$makeNumber(a), this.$makeNumber(b)),
          0,
        );
      if (total_unpass_quantity != payload.seq_fail_quantity) {
        this.openOneButtonModal(
          '등록불가',
          '검사 불량 수량의 합은 공정 불량 수량과 같아야합니다.',
        );
        return;
      } else {
        payload.check_quantity = this.$decimalAdd(
          this.$makeNumber(payload.seq_pass_quantity),
          this.$makeNumber(payload.seq_fail_quantity),
        );

        payload.unpass_quantity = payload.seq_defect_list
          .map(x => x.unpass_quantity)
          .reduce((a, b) =>
            this.$decimalAdd(this.$makeNumber(a), this.$makeNumber(b)),
          );

        payload.pass_quantity = this.$decimalSub(
          this.$makeNumber(payload.check_quantity),
          this.$makeNumber(payload.unpass_quantity),
        );

        payload.seq_defect_list.forEach((el, index) => {
          el.lot_seq_id = payload.id;
          if (index == 0) {
            el.check_quantity = payload.check_quantity;
            el.pass_quantity = this.$decimalSub(
              this.$makeNumber(payload.check_quantity),
              this.$makeNumber(el.unpass_quantity),
            );
          } else {
            el.check_quantity =
              payload.seq_defect_list[index - 1].pass_quantity;
            el.pass_quantity = this.$decimalSub(
              this.$makeNumber(el.check_quantity),
              this.$makeNumber(el.unpass_quantity),
            );
          }
        });
        payload.saup_no = this.systemCompany.saup_no;
        console.log(payload);

        if (payload.seq_quality_id != null) {
          this.$store
            .dispatch('UPDATE_SEQ_QUALITY', payload)
            .then(() => {
              this.FETCH_SEQ_DEFECT_PRODUCTION();
            })
            .catch(() => {
              this.openOneButtonModal('수정 실패', '수정 중 오류발생');
            })
            .finally(() => {});
        } else {
          this.$store
            .dispatch('INSERT_SEQ_QUALITY', payload)
            .then(() => {
              this.FETCH_SEQ_DEFECT_PRODUCTION();
            })
            .catch(() => {
              this.openOneButtonModal('등록 실패', '등록 중 오류발생');
            })
            .finally(() => {});
        }
      }
    },
    setSearchType(arg, index) {
      if (arg == 'new') {
        this.seq_verification.seq_defect_list[index].verification_id = null;
        this.showModal = true;
      } else {
        this.seq_verification.seq_defect_list[index].verification_id = arg;
      }
    },
    async FETCH_SEQ_DEFECT_PRODUCTION() {
      await this.$store
        .dispatch('FETCH_SEQ_DEFECT_PRODUCTION', this.seq_id)
        .then(response => {
          response.data.data.seq_defect_list.forEach(el => {
            el.image = null;
          });
          this.seq_verification = response.data.data;
          this.cloneData = this.lodash.clonedeep(response.data.data);
        })
        .catch(() => {});
    },
  },
  async created() {
    if (this.systemCompany.id == undefined) {
      await this.FETCH_NO_SPINNER('FETCH_SYSTEM_COMPANY', '사용자 사업자');
    }
    if (this.seq_id != undefined) {
      await this.FETCH_SEQ_DEFECT_PRODUCTION();
    }
    if (this.seq_verifications.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_BASE_VERIFICATION');
    }
    this.showSelectric = true;
  },
};
</script>

<style lang="scss" scoped></style>
