<template>
  <div class="modalComponent">
    <div
      v-show="show_modal"
      class="modal three_btn_modal"
      id="errorModal"
      :style="show_modal == false ? 'top: 50%; left: 50%;' : ''"
    >
      <div class="modal_header">
        <h3 class="title">{{ modal_title }}</h3>
        <button class="modal_close" @click="CloseModal"></button>
      </div>
      <div class="modal_body" v-html="modal_content">
        <!-- {{ modal_content }} -->
      </div>
      <div class="modal_footer">
        <button @click="onClickYesBtn">예</button>
        <button @click="onClickNoBtn">아니오</button>
        <button @click="CloseModal">취소</button>
      </div>
    </div>
    <div v-if="show_modal" class="modal_layer"></div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  mixins: [DRAG_MODAL_MIXIN],
  props: {
    modal_title: {
      type: String,
      required: true,
    },
    modal_content: {
      type: String,
      required: true,
    },
    show_modal: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClickYesBtn() {
      this.$emit('onYes');
    },
    onClickNoBtn() {
      this.$emit('onNo');
    },
    CloseModal() {
      this.$emit('onClose');
    },
  },
};
</script>

<style scoped></style>
